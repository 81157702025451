.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.banner {
  width: 100%;
  position: absolute;
  left: 50%;
  margin-left: -50%;
  z-index: -1;
}

.banner-img {
  height: 500px;
  width: 100%;
}

.banner-content {
  width: 1260px;
  position: relative;
}

.banner-title {
  width: 155px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 20px;
}

.banner-logo {
  width: 351px;
  height: 36px;
  margin-top: 171px;
  margin-bottom: 20px;
}

.banner-text {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  font-style: normal;
  line-height: 32px;
}

.banner-heart {
  position: absolute;
  top: 153px;
  right: 0;
  width: 360px;
  height: 228px;
}

.banner-shadow {
  position: absolute;
  right: 43px;
  top: 369px;
  width: 274px;
  height: 80px;
  background: radial-gradient(ellipse, rgba(11, 36, 52, 0.2) 0%, rgba(11, 36, 52, 0.15) 10%, rgba(11, 36, 52, 0.13) 15%, rgba(255, 255, 255, 0) 40%,rgba(255, 255, 255, 0) 100%);
  opacity: 0.7;
}

.banner-button {

  width: 168px;
  height: 57px;
  background: linear-gradient(127deg, #8AF0FF 0%, #3296FF 100%);
  box-shadow: 0px 8px 15px 0px rgba(48, 60, 65, 0.11);
  border-radius: 34px;

  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 37px;
  font-style: normal;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
  cursor: pointer;
}

.banner-button:hover {
  width: 168px;
  height: 57px;
  background: linear-gradient(127deg, #4CDAEE 0%, #0F7CEF 100%);
  box-shadow: 0px 8px 15px 0px rgba(48, 60, 65, 0.11);
  border-radius: 34px;

  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 26px;
  color: #FFFFFF;
  line-height: 37px;
  text-align: left;
  font-style: normal;
}

.projDesc-wrap {
  width: 1260px;
  height: 565px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.projDesc-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  line-height: 45px;
  font-style: normal;
  margin: 80px 0 40px 0;
}

.projDesc-description {
  width: 1260px;
  height: 340px;
  background: #C4E8FF;
  border-radius: 10px;
  position: relative;
}

.projDesc-img {
  width: 720px;
  height: 340px;
  position: absolute;
  top: 0;
  right: 0;
  clip-path: polygon(14% 0, 100% 0, 100% 100%, 0 100%);
  z-index: 10;
}

.projDesc-description-text {
  position: absolute;
  left: -86px;
  top: 0;
  width: 720px;
  height: 340px;
  background: #F3F7FA;
  clip-path: polygon(0% 0, 100% 0, 86% 100%, 0 100%);
}

.projDesc-text {
  position: absolute;
  left: 60px;
  top: 92px;
  width: 440px;
  height: 156px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  line-height: 36px;
  text-align: left;
  font-style: normal;
  text-indent: 36px;
}

.text-top-line {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 593px;
  height: 1px;
  background-image: linear-gradient(to right, #0054AD 0%, #0054AD 50%, transparent 50%);
  background-size: 10px 1px;
  background-repeat: repeat-x;
  opacity: 0.4;
}

.text-left-line {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 1px;
  height: 280px;
  background-image: linear-gradient(to bottom, #0054AD 0%, #0054AD 50%, transparent 50%);
  background-size: 1px 10px;
  background-repeat: repeat-y;
  opacity: 0.4;
}

.text-bottom-line {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 593px;
  height: 1px;
  background-image: linear-gradient(to right, #0054AD 0%, #0054AD 50%, transparent 50%);
  background-size: 10px 1px;
  background-repeat: repeat-x;
  opacity: 0.4;
}

.rightView-top-line {
  position: absolute;
  top: 26px;
  right: 30px;
  width: 608px;
  height: 10px;
  opacity: 0.6;
  z-index: 11;
  background: #FFFFFF;
}

.rightView-right-line {
  position: absolute;
  top: 36px;
  right: 30px;
  width: 10px;
  height: 280px;
  opacity: 0.6;
  z-index: 11;
  background: #FFFFFF;
}

.rightView-bottom-line {
  position: absolute;
  bottom: 24px;
  right: 40px;
  width: 682px;
  height: 10px;
  opacity: 0.6;
  z-index: 11;
  background: #FFFFFF;
}

.highlights-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 540px;
  width: 100%;
  position: relative;
  overflow: hidden;

  background: #F8FCFF;
}

.highlights {
  width: 1260px;
  height: 540px;
  position: relative;
  display: flex;
  align-items: center;
}

.highlights-title {
  display: flex;
  height: 540px;
  width: 220px;
  position: relative;
}

.highlights-image {
  width: 510px;
  height: 540px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.highlight-img {
  width: 510px;
  height: 198px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}


.highlight-img-text {
  margin-right: 53px;
  width: 120px;
  height: 30px;
  font-size: 30px;
  color: #333333;
  line-height: 35px;
  text-align: left;
  font-style: normal;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 800;
}

.highlights-container {
  display: flex;
  flex: 1;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 60px 0;
  box-sizing: border-box;
  height: 540px;
}

.highlights-container-item {
  display: flex;
  justify-content: space-between;
}

.highlights-container-line {
  display: flex;
  width: 100%;
  height: 2px;
  background: #EFEFEF;
}

.highlight-item {
  height: 198px;
  width: 420px;
}

.highlight-item-title {
  width: 96px;
  height: 33px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 800;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  position: relative;
  margin-top: 28px;
  margin-bottom: 20px;
}

.highlight-item-back {
  width: 96px;
  height: 14px;
  background: #B8D5FF;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.highlight-item-text {
  z-index: 10;
  position: absolute;
}

.item2 {
  background: #D9F3E2;
}

.item3 {
  background: #FFE4D3;
}

.item4 {
  background: #C9E0FF;
}

.highlight-item-content {
  width: 420px;
  height: 64px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 32px;
  text-align: left;
  font-style: normal;
}

.application-wrap {
  width: 1260px;
  height: 649px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.application-title {
  width: 384px;
  height: 45px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  line-height: 45px;
  text-align: right;
  font-style: normal;
  margin-top: 60px;
  margin-bottom: 30px;
}

.application-tip {
  width: 1230px;
  height: 64px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  margin-bottom: 30px;
  text-indent: 32px;
}

/* 卡片容器布局 */
.card-container {
  width: 1360px;
  display: flex;
  gap: 16px;
  /* 卡片间距 */
  overflow-x: auto;
  /* 横向滚动 */
  justify-content: space-evenly;
}

/* 单张卡片样式 */
.card {
  flex: 0 0 180px;
  /* 固定宽度，灵活布局 */
  height: 360px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.card-img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: inherit;
  filter: blur(8px);
  transform: scale(1.1);
}

/* 卡片标题 */
.card-title-wrap {
  margin-top: 32px;
  z-index: 2;
  width: 100%;
  height: 33px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 800;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: center;
  font-style: normal;
  margin-left: 0px;
}

.card-title-wrap-on {
  color: #FFFFFF;
  text-align: left;
  margin-left: 32px;
}

/* 卡片描述 */
.card-description {
  width: 320px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  transform: translateY(20px);
  opacity: 0;
  z-index: 2;
  position: absolute;
  bottom: 32px;
  left: 32px;
}




.card::before {
  transition: 0s 9999s;
}

/* 初始状态（第一个选中） */
.card:first-child {
  flex: 0 0 380px;
}
.card:first-child .card-img{
  filter: none;
}

.card:first-child .card-title-wrap {
  color: #FFFFFF;
  text-align: left;
  margin-left: 32px;
}

.card:first-child .card-description {
  opacity: 1;
}

/* 清除所有hover */
.card-container:hover .card-img{
  filter: blur(8px);
  transition: all 0.3s ease-in-out;
}

.card-container:hover .card {
  transition: all 0.3s ease-in-out;
  flex: 0 0 180px;
}

.card-container:hover .card-title-wrap {
  color: #333333;
  margin-left: 0px;
  text-align: center;

  transition: all 0.3s ease-in-out;

}

.card-container:hover .card-description {
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

/* 每一项hover */
.card:hover .card-img{
  filter: none;
  transition: all 0.3s ease-in-out;
}

.card.card:hover {
  flex: 0 0 380px;
  transition: all 0.3s ease-in-out;
}

.card:hover .card-title-wrap {
  color: #FFFFFF;
  text-align: left;
  margin-left: 32px;
  transition: all 0.3s ease-in-out;
}

.card:hover .card-description {
  opacity: 1;
  transition: all 0.3s ease-in-out;

}


.sample-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 610px;
  width: 100%;
  position: relative;
  overflow: hidden;

  background: #F9F9F9;
}

.sample {
  width: 1260px;
  height: 610px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sample-title {
  width: 128px;
  height: 45px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  line-height: 45px;
  text-align: right;
  font-style: normal;
  margin-top: 60px;
  margin-bottom: 40px;
}

.sample-img {
  width: 463px;
  height: 336px;

background: #FFFFFF;
box-shadow: 0px 6px 12px 0px rgba(165,165,165,0.5), 0px 2px 2px 0px rgba(0,0,0,0.2);
}

.sample-image-wrap {
  display: flex;
}

.sample-img-text {
  width: 463px;
  text-align: center;

  height: 33px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  font-style: normal;
  margin-top: 32px;
}

.video-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.video {
  width: 100%;
  position: absolute;
  left: 50%;
  margin-left: -50%;
  z-index: -1;
}

.video-img {
  height: 800px;
  width: 100%;
}

.video-title {
  width: 128px;
  height: 45px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  line-height: 45px;
  text-align: right;
  font-style: normal;
  margin-top: 60px;
  margin-bottom: 40px;
}

.footer-wrap {
  width: 1260px;
  height: 226px;
  background: #FFFFFF;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-img {
  width: 220px;
  height: 57px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-around;
}

.footer-qrcode-img {
  width: 120px;
  height: 120px;
  border: 2px solid #1960C1;
}

.footer-img-tip {
  width: 120px;
  height: 25px;
  display: flex;
  justify-content:center;

  font-family: PingFang-SC, PingFang-SC;
  font-weight: 500;
  font-size: 18px;
  color: #666666;
  line-height: 25px;
  text-align: center;
  font-style: normal;
}

.footer-code {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  background: #F3F3F3;
  text-align: center;

  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  color: #666666;
  line-height: 22px;
  font-style: normal;
  cursor: pointer;
}

.modal-wrap {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.modal-header {
  width: 100%;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;



  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 24px;
  color: #0C142E;
  line-height: 33px;
  text-align: right;
  font-style: normal;
  position: relative;
}

.model-header-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 126px;
  border-radius: 10px;

}

.modal-header-text {
  z-index: 2;
}

.model-content {
  width: 100%;
  height: 368px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-content-img {
  width: 260px;
  height: 260px
}

.footer-phone {
  width: 20px;
  height: 24px;
  margin-right: 14px;
}

.content-info-wrapper {
  display: flex;
  align-items: center;
}